import * as React from "react";
import { Container, Row, Col, ProgressBar, Button, Card } from "react-bootstrap"
import Icon1 from '../../content/assets/magnifying-glass.svg'
import Icon2 from '../../content/assets/computer.svg'
import Icon3 from '../../content/assets/truck.svg'
import Icon4 from '../../content/assets/survey.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown, faAngleDoubleUp, faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

const WebDevelopment = () => {

  const ArrowTopBottom = styled.div`
    display: flex;
    justify-content: center;
  `
  const ArrowContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content:center;
    width: 100%;
  `
  const ArrowSideRight = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content:flex-end;
  `
  const ArrowSideLeft = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content:flex-start;
  `
  return (
    <Container className=''>
      <Row className='mt-5 mb-5'>
        <Col md={12}>
          <h2>We build websites and long lasting relationships.</h2>
        </Col>
        <Col md={8} className=''>
          <p>Since 2015 Wolf on Moon has been creating websites and delivering strategic digital communication and information solutions to small businesses and select clients around the world. If your business is looking for a new website or other digital services we would encourage you to get in touch.</p>
        </Col>
        <Col md={{ span: 4, offset: 0}}>
            <ArrowTopBottom>
              <FontAwesomeIcon className='down-arrow neon' icon={faAngleDoubleDown} size="lg" />
            </ArrowTopBottom>
            <ArrowContainer>
            <ArrowSideLeft>
              <FontAwesomeIcon className='down-arrow neon' icon={faAngleDoubleRight} size="lg" />
            </ArrowSideLeft>
            <Button href='https://wlfnmn.typeform.com/to/Q68W4Nsd' className='m-2' variant='primary'>Get a website</Button>
            <ArrowSideRight>
              <FontAwesomeIcon className='down-arrow neon' icon={faAngleDoubleLeft} size="lg" />
            </ArrowSideRight>
            </ArrowContainer>
            <ArrowTopBottom>
            <FontAwesomeIcon className='down-arrow neon' icon={faAngleDoubleUp} size="lg" />
          </ArrowTopBottom>
        </Col>
        <Col md={{offset: 3, span: 6}}>
          <Row>
          </Row>
        </Col>
        <Col lg={6} md={12} sm={12} className='pt-5 pb-3'>
          <Card className='p-4'>
          <Row>
            <Col md={12}>
              <h3>Discovery</h3>
              <hr />
            </Col>
            <Col md={8}>
              <p>We learn all about your business proposition and brand and construct a plan.</p>
            </Col>
          </Row>
          <ProgressBar>
            <ProgressBar striped animated variant="success" now={34} label='33%' />
            <ProgressBar animated variant="warning" now={67} label='66%'/>
          </ProgressBar>
          </Card>
        </Col>
        <Col lg={6} md={12} sm={12} className='pt-5 pb-3'>
        <Card className='p-4'>
          <Row>
          <Col md={12}>
            <h3>Build</h3>
            <hr />
          </Col>
          <Col md={8}>
            <p>We put together what we learned from the Discovery phase into a working product.</p>
          </Col>
          <Col md={12}>
          <ProgressBar>
            <ProgressBar animated variant="success" now={67} label='66%'/>
            <ProgressBar animated variant="warning" now={34} label='33%'/>
          </ProgressBar>
          </Col>
          </Row>
          </Card>
        </Col>
        <Col lg={6} md={12} sm={12} className='pt-0 pb-3'>
        <Card className='p-4'>
          <Row>
            <Col md={12}>
              <h3>Delivery</h3>
              <hr />
            </Col>
            <Col md={8}>
            <p>We turn everything on and handover our work, ensuring everything is running smoothly.</p>
            </Col>
          </Row>
          <ProgressBar>
            <ProgressBar animated variant="success" now={100} label='100%'/>
          </ProgressBar>
          </Card>
        </Col>
        <Col lg={6} md={12} sm={12} className='pt-0 pb-3'>
        <Card className='p-4'>
          <Row>
            <Col md={12}>
              <h3>Review</h3>
              <hr />
            </Col>
            <Col md={8}>
            <p>We conduct a detailed investigation of satisfaction with our work and provide opinions. </p>
            </Col>
          </Row>
          <ProgressBar>
            <ProgressBar variant="success" now={100} label=''/>
          </ProgressBar>
          </Card>
        </Col>
        </Row>
    </Container>
  );
};


export default WebDevelopment